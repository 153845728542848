.dashboard-feature {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}
.dashboard-feature__card {
    box-shadow: var(--base-card-classic-hover-box-shadow-outer) !important;
}

.dashboard-feature__card::after {
    box-shadow: 0 0 0 0#ffffff !important;
}
